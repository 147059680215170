import React, { useState } from "react";
import Select from "react-select";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import "../../Pricing.css";
import { LuRefreshCcw } from "react-icons/lu";
import CompareExplore from "./CompareExplore";

const currentYear = new Date().getFullYear();
const startYear = 2014;
const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i).map((year) => ({
  value: year,
  label: year,
}));

const months = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

const Explore = (props) => {
  const [cropName, setCropName] = useState(null);
  const [marketName, setMarketName] = useState(null);
  const [policyStartYear, setPolicyStartYear] = useState(null);
  const [policyEndYear, setPolicyEndYear] = useState(null);
  const [monitoringStartMonth, setMonitoringStartMonth] = useState(null);
  const [monitoringEndMonth, setMonitoringEndMonth] = useState(null);
  const [deTrendData, setDeTrendData] = useState("no");
  const [showCompare, setShowCompare] = useState(false); // State to control Generate component visibility
  const [compareclicked, setcompareclicked] = useState(1); // State to control Generate component visibility

  const isFormValid =
    cropName &&
    marketName &&
    policyStartYear &&
    policyEndYear &&
    monitoringStartMonth &&
    monitoringEndMonth &&
    deTrendData;

  const handleResetbuttonlick = () => {
    setCropName(null);
    setMarketName(null);
    setPolicyStartYear(null);
    setPolicyEndYear(null);
    setMonitoringStartMonth(null);
    setMonitoringEndMonth(null);

    setDeTrendData("no");
    setcompareclicked(1)
    setShowCompare(false); // Hide Generate component on clear
  };

  const handleGenerate = () => {
    if (isFormValid) {
      setShowCompare(true); 
      setcompareclicked(prev => prev + 1);// Show Generate component
    }
  };

  return (
    <div className="compare-pannel">
      <div>
        <div className="exploretext">Explore</div>
        <div className="compareecondarytext">
          Make required selections to view available datasets.
        </div>

        <div className="download-container">
          <h2 className="select-label">Crop Name</h2>
          <Select
            className="custom-select"
            placeholder="Crop "
            value={cropName ? { label: cropName, value: cropName } : null}
            onChange={(selectedOption) => {setCropName(selectedOption.value)
                                             setMarketName(null)}}
            options={[{ value: "Red Chilly", label: "Red Chilly" },
            { value: "Soyabean", label: "Soyabean" }]}
          />
        </div>

        <div className="download-container">
          <h2 className="select-label">Market Name</h2>
          <Select
            className="custom-select"
            placeholder="Market "
            value={marketName ? { label: marketName, value: marketName } : null}
            onChange={(selectedOption) => setMarketName(selectedOption.value)}
            options={
              cropName === "Red Chilly"
                ? [{ value: "Guntur", label: "Guntur" }]
                : cropName === "Soyabean"
                ? [{ value: "Indore", label: "Indore" }]
                : []
            }
          />
        </div>

        {/* Policy Duration */}
        <div className="download-container">
          <h2 className="select-label">Select Start and End Year</h2>
          <div className="date-row">
            <div className="date-item">
              <Select
                className="custom-select"
                placeholder="Start Year"
                value={policyStartYear? { label: policyStartYear, value: policyStartYear } : null}
                onChange={(selectedOption) =>
                  setPolicyStartYear(selectedOption.value)
                }
                options={years}
              />
            </div>
            <div className="date-item">
              <Select
                className="custom-select"
                placeholder="End Year"
                value={policyEndYear? { label: policyEndYear, value: policyEndYear } : null}
                onChange={(selectedOption) => setPolicyEndYear(selectedOption.value)}
                options={years}
              />
            </div>
          </div>
        </div>

        {/* Monitoring Duration */}
        <div className="download-container">
          <h2 className="select-label">
            Select Start and End Month 
          </h2>
          <div className="date-row">
            <div className="date-item">
              <Select
                className="custom-select"
                placeholder="Start Month"
                value={monitoringStartMonth? { label: monitoringStartMonth, value: monitoringStartMonth } : null}
                onChange={(selectedOption) =>
                  setMonitoringStartMonth(selectedOption.value)
                }
                options={months}
              />
            </div>
            <div className="date-item">
              <Select
                className="custom-select"
                placeholder="End Month"
                value={monitoringEndMonth? { label: monitoringEndMonth, value: monitoringEndMonth } : null}
                onChange={(selectedOption) =>
                  setMonitoringEndMonth(selectedOption.value)
                }
                options={months}
              />
            </div>
          </div>
        </div>

        <div className="download-container">
          <h2 className="select-label">De-Trend Data</h2>
          <RadioGroup
            row
            value={deTrendData}
            onChange={(event) => setDeTrendData(event.target.value)}
          >
            <FormControlLabel value="yes" disabled control={<Radio />} label="Yes" />
            <FormControlLabel value="no" disabled control={<Radio />} label="No" />
          </RadioGroup>
        </div>

        <div className="compare-button-container">
        <Button
            variant="contained"
            style={{
              backgroundColor: isFormValid ? "#0880EA" : "#ccc",
              width:'300px',
              color: "#fff",
            }}
            onClick={handleGenerate}
            disabled={!isFormValid}
          >
            Compare
          </Button>
          <div className="resetbutton" onClick={handleResetbuttonlick}>
            <LuRefreshCcw style={{ marginRight: "10px" }} />
            Reset selections
          </div>
        </div>
      </div>

      {
        <div
          className="generate-panel"
          style={{
            position: "fixed",
            right: "0",
            top: "0",
            width: "calc(100vw - 470px)",
            height: "100vh",
            backgroundColor: "#fff",
            zIndex: "1000",
            padding: "24px",
            borderLeft: "1px solid rgba(26, 26, 0, 0.16)",
            overflow: "auto",
            marginTop: "40px",
          }}
        >
          {console.log(policyEndYear)}
          <CompareExplore
           compareclicked={compareclicked}
           marketName={marketName}
           cropName={cropName}
           policyStartYear={policyStartYear}
           policyEndYear={policyEndYear}
           monitoringStartMonth={monitoringStartMonth}
           monitoringEndMonth={monitoringEndMonth}
           showCompare={showCompare} 
           />
        </div>
      }
    </div>
  );
};

export default Explore;
