import React, { useState } from "react";
import "../styles.css";
import { IoIosLogOut } from "react-icons/io";

const Header = (props) => {
  const [isProfileOpen, setProfileOpen] = useState(false);

  const headerStyle = {
    height: "48px",
    padding: "6px 16px",
    borderBottom: "1px solid #E0E0E0",
    background: "#FEFCFB",
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: "1001",
  };

  const iconsContainerStyle = {
    display: "flex",
    gap: "24px",
    alignItems: "center",
  };

  const handleProfileClick = () => {
    setProfileOpen(!isProfileOpen);
  };

  const handleLogout = () => {
    window.location.reload();
  };

  const profilePopupStyle = {
    position: "fixed",
    top: "48px",
    right: "10px",
    width: "275px",
    height: "104px",
    background: "#fff",
    padding: "24px",
    border: "1px solid rgba(26, 26, 0, 0.16)",
    zIndex: 10002,
    display: isProfileOpen ? "flex" : "none",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px 0px rgba(28, 28, 2, 0.08)",
    alignItems: "center",
    flexDirection: "column",
  };

  return (
    <div>
      <div style={headerStyle}>
        <div style={iconsContainerStyle}>
          <div
            className="label"
            style={{
              color: "#1c2024",
              left: 0,
              letterSpacing: 0,
              position: "relative",
            }}
          >
            Price Insurance Calculator
          </div>
        </div>
        <div
          style={{ display: "flex", gap: "20px" }}
          onClick={handleProfileClick}
        >
          <img
            src="Avatar.png"
            alt="Profile Icon"
            style={{ height: "24px", width: "24px" }}
          />
        </div>
      </div>
      {isProfileOpen ? (
        <div style={profilePopupStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src="Avatar.png"
              alt="Profile Icon"
              style={{ height: "24px", width: "24px" }}
            />
            <div
              style={{
                fontSize: "12px",
                marginRight: "8px",
                marginLeft: "8px",
              }}
            >
              {props.email}
            </div>
          </div>
          <div style={{ alignItems: "center" }}>
            <hr
              style={{
                width: "250px",
                height: "1px",
                border: "1px solid rgba(26, 26, 1, 0.12)",
                margin: "8px 0",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
              onClick={handleLogout}
            >
              <IoIosLogOut color="#E5484D" />
              <div
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                  color: "#E5484D",
                  marginLeft: "5px",
                }}
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
