import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

const PriceTrend = ({ data }) => {
  const formatData = (seriesData) => {
    return seriesData.map(item => [item.date * 1000, item.price]);
  };

  const agmarknetData = data?.Agmarknet ? formatData(data.Agmarknet) : [];
  const eNamData = data?.['e-NAM'] ? formatData(data['e-NAM']) : [];

  const options = {
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Date'
      }
    },
    yAxis: {
      title: {
        text: 'Price'
      }
    },
    series: [
      {
        name: 'Agmarknet',
        data: agmarknetData,
        color: '#18794E',
        tooltip: {
          valueSuffix: ' per Quintal'
        }
      },
      {
        name: 'e-NAM',
        data: eNamData,
        color: '#0B68CB',
        tooltip: {
          valueSuffix: ' per Quintal'
        }
      }
    ],
    tooltip: {
      shared: true,
      crosshairs: true
    },
    legend: {
      enabled: true, // Enable the legend to allow series toggling
      layout: 'horizontal', // Arrange legend items horizontally
      align: 'center', // Center the legend horizontally
      verticalAlign: 'top', // Position the legend at the top
      floating: false, // Ensures the legend is not floating
      y: 10, // Add some spacing from the top
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      />
    </div>
  );
};

export default PriceTrend;
