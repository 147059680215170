import React, { useState } from "react";
import Select from "react-select";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import Generate from "./Generate"; // Import the Generate component
import "../../Pricing.css";

const Pricing = (props) => {
  const [cropName, setCropName] = useState(null);
  const [marketName, setMarketName] = useState(null);
  const [priceSource, setPriceSource] = useState(null);
  const [priceUnit, setPriceUnit] = useState(null);
  const [policyStartDate, setPolicyStartDate] = useState("");
  const [policyEndDate, setPolicyEndDate] = useState("");
  const [monitoringStartDate, setMonitoringStartDate] = useState("");
  const [monitoringEndDate, setMonitoringEndDate] = useState("");
  const [strikePrice, setStrikePrice] = useState("");
  const [exitPrice, setExitPrice] = useState("");
  const [pricingMethod, setPricingMethod] = useState(null);
  const [deTrendData, setDeTrendData] = useState("no");
  const [deductible, setDeductible] = useState("");
  const [maxPayout, setMaxPayout] = useState("");
  const [minPayout, setMinPayout] = useState("");
  const [showGenerate, setShowGenerate] = useState(false); // State to control Generate component visibility
  const [generateclicked, setgenerateclicked] = useState(1); // State to control Generate component visibility
  const [endDateError, setEndDateError] = useState(false);
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setMonitoringStartDate(selectedStartDate);

    // Re-validate end date when start date changes
    if (
      monitoringEndDate &&
      new Date(selectedStartDate) > new Date(monitoringEndDate)
    ) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    setMonitoringEndDate(selectedEndDate);

    // Check if the end date is earlier than the start date
    if (
      monitoringStartDate &&
      new Date(selectedEndDate) < new Date(monitoringStartDate)
    ) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  };

  const isFormValid =
    cropName &&
    marketName &&
    priceSource &&
    monitoringStartDate &&
    monitoringEndDate &&
    strikePrice &&
    exitPrice &&
    pricingMethod &&
    priceUnit;

  const handleClear = () => {
    setCropName(null);
    setMarketName(null);
    setPriceSource(null);
    setPolicyStartDate("");
    setPolicyEndDate("");
    setMonitoringStartDate("");
    setMonitoringEndDate("");
    setStrikePrice("");
    setExitPrice("");
    setPricingMethod(null);
    setDeTrendData("no");
    setDeductible("");
    setMaxPayout("");
    setMinPayout("");
    setShowGenerate(false);
    setgenerateclicked(1);
  };

  const handleGenerate = () => {
    if (isFormValid) {
      setShowGenerate(true);
      setgenerateclicked((prev) => prev + 1);
    }
  };

  return (
    <div className="compare-pannel">
      <div>
        <div className="exploretext">Premium Calculation</div>
        <div className="compareecondarytext">
          Fill the required input fields to calculate the pure premium
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Crop Name <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Crop Name"
            value={cropName ? { label: cropName, value: cropName } : null}
            onChange={(selectedOption) => {setCropName(selectedOption.value)
            setMarketName(null)
          setPriceSource(null)}}
            options={[
              { value: "Red Chilly", label: "Red Chilly" },
              { value: "Soyabean", label: "Soyabean" },
            ]}
          />
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Market Name <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Market Name"
            value={marketName ? { label: marketName, value: marketName } : null}
            onChange={(selectedOption) => setMarketName(selectedOption.value)}
            options={
              cropName === "Red Chilly"
                ? [{ value: "Guntur", label: "Guntur" }]
                : cropName === "Soyabean"
                ? [{ value: "Indore", label: "Indore" }]
                : []
            }
          />
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Price Source <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Price Source"
            value={
              priceSource ? { label: priceSource, value: priceSource } : null
            }
            onChange={(selectedOption) => setPriceSource(selectedOption.value)}
            options={
              cropName === "Red Chilly"
                ? [
                    { value: "e-NAM", label: "e-NAM" },
                    { value: "Agmarknet", label: "Agmarknet" },
                  ]
                : cropName === "Soyabean"
                ? [{ value: "Agmarknet", label: "Agmarknet" }]
                : []
            }
          />
        </div>

        {/* Policy Duration */}
        {/* <div className="download-container">
          <h2 className="select-label">Policy Duration</h2>
          <div className="date-row">
            <div className="date-item">
              <h2 className="date-label">Start Date</h2>
              <TextField
                id="policyStartDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={policyStartDate}
                onChange={(e) => setPolicyStartDate(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="date-label">End Date</h2>
              <TextField
                id="policyEndDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={policyEndDate}
                onChange={(e) => setPolicyEndDate(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
          </div>
        </div> */}

        {/* Monitoring Duration */}
        <div className="download-container">
          <h2 className="select-label">
            Monitoring Duration <span className="mandatory">*</span>
          </h2>
          <div className="date-row">
            <div className="date-item">
              <h2 className="date-label">Start Date</h2>
              <TextField
                id="monitoringStartDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={monitoringStartDate}
                onChange={handleStartDateChange}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="date-label">End Date</h2>
              <TextField
                id="monitoringEndDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={monitoringEndDate}
                onChange={handleEndDateChange}
                error={endDateError} // Shows red border if there's an error
                helperText={
                  endDateError
                    ? "End Date cannot be earlier than Start Date"
                    : ""
                }
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": {
                    height: "45px",
                    fontSize: "14px",
                    borderColor: endDateError ? "red" : "inherit",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="download-container">
          <h2 className="select-label">
            Price Unit <span className="mandatory">*</span>
          </h2>
          <Select
            className="custom-select"
            placeholder="Price unit"
            value={priceUnit ? { label: priceUnit, value: priceUnit } : null}
            onChange={(selectedOption) => setPriceUnit(selectedOption.value)}
            options={[
              { value: "Rs/Quintal", label: "Rs/Quintal" },
              { value: "Rs/kg", label: "Rs/kg" },
            ]}
          />
        </div>
        <div className="download-container">
          <div className="date-row">
            <div className="date-item">
              <h2 className="select-label">
                Strike Price <span className="mandatory">*</span>
              </h2>
              <TextField
                id="strikePrice"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={strikePrice}
                onChange={(e) => setStrikePrice(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="select-label">
                Exit Price <span className="mandatory">*</span>
              </h2>
              <TextField
                id="exitPrice"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={exitPrice}
                onChange={(e) => setExitPrice(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
          </div>
        </div>

        <div className="download-container">
          <div className="date-row">
            <div className="date-item">
              <h2 className="select-label">
                Pricing Method <span className="mandatory">*</span>
              </h2>
              <Select
                className="custom-select"
                placeholder="Select Method"
                value={pricingMethod}
                onChange={(selectedOption) => setPricingMethod(selectedOption)}
                options={[
                  { value: "simple", label: "Simple" },
                  { value: "average", label: "Average" },
                ]}
              />
            </div>
            <div className="date-item">
              <h2 className="select-label">De-Trend Data</h2>
              <RadioGroup
                row
                value={deTrendData}
                onChange={(event) => setDeTrendData(event.target.value)}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </div>
          </div>
        </div>

        <div className="download-container">
          <h2 className="select-label">Deductible (%)</h2>
          <TextField
            id="deductible"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={deductible}
            onChange={(e) => setDeductible(e.target.value)}
            sx={{
              margin: "4px 0",
              ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
            }}
          />
        </div>

        <div className="download-container" style={{ marginBottom: "100px" }}>
          <div className="date-row">
            <div className="date-item">
              <h2 className="select-label">Maximum Payout</h2>
              <TextField
                id="maxPayout"
                type="text"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={maxPayout}
                onChange={(e) => setMaxPayout(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="select-label">Minimum Payout</h2>
              <TextField
                id="minPayout"
                type="text"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={minPayout}
                onChange={(e) => setMinPayout(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="button-container"
          style={{
            position: "fixed",
            bottom: "0",
            width: "380px",
            backgroundColor: "white",
            zIndex: "1000",
            padding: "15px 20px",
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid #ccc",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#F1F5F9",
              color: "#000",
              marginRight: "20px",
            }}
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: isFormValid ? "#0880EA" : "#ccc",
              color: "#fff",
            }}
            disabled={!isFormValid}
            onClick={handleGenerate}
          >
            Generate
          </Button>
        </div>
      </div>

      {showGenerate && (
        <div
          className="generate-panel"
          style={{
            position: "fixed",
            right: "0",
            top: "0",
            width: "calc(100vw - 470px)",
            height: "100vh",
            backgroundColor: "#fff",
            zIndex: "1000",
            padding: "24px",
            borderLeft: "1px solid rgba(26, 26, 0, 0.16)",
            overflow: "auto",
            marginTop: "40px",
          }}
        >
          <Generate
            showGenerate={showGenerate}
            cropName={cropName}
            monitoringStartDate={monitoringStartDate}
            monitoringEndDate={monitoringEndDate}
            strikePrice={strikePrice}
            exitPrice={exitPrice}
            priceSource={priceSource}
            priceUnit={priceUnit}
            marketName={marketName}
            generateclicked={generateclicked}
          />
        </div>
      )}
    </div>
  );
};

export default Pricing;
