import React, { useState, useEffect } from "react";
import { Button, Card } from "reactstrap";
import { PriceComparison } from "./PriceComparison";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { BiDownload } from "react-icons/bi";

export const Monitor = (props) => {
  const [value, setValue] = React.useState("1");
  
  const [comparisonData, setComparisonData] = useState(null);
  const [policiesdetails, setPoliciesDetails] = useState([]);
  const endorseeData = [
    {
      _id: "1",
      aggregator: "Atchampeta Farmers Producer Company Limited",
      endorsee_name: "John Doe",
      quantity: 1000,
      unit: "kg",
      sum_insured: "1,00,000",
      endorsee_count: 2,
      status: "live"
    },
    {
      _id: "2",
      aggregator: "Yeruvaka Agri Producer Company Limited",
      endorsee_name: "Jane Smith",
      quantity: 1500,
      unit: "kg",
      sum_insured: "1,00,000",
      endorsee_count: 2,
      status: "closed"
    },
    
  ];
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch endorsee data
  // useEffect(() => {
  //   fetch("/api/monitor/getEndorseeData", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       policy_number: props.selectedPolicyNo,
  //       master_holder: "Samunnati",
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => setEndorseeData(data));
  // }, []);

  // Fetch price comparison data
  useEffect(() => {
    fetch("/api/monitor/priceComparisonGraph?policy_number="+props.selectedPolicyNo, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setComparisonData(data));
  }, []);
  useEffect(() => {
    fetch("/api/monitor/getPolicyDetails?policy_number="+props.selectedPolicyNo, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setPoliciesDetails(data));
  }, [props.selectedPolicyNo]);

  return (
    <>
      <div
        className="row justify-content-center "
        style={{
          overflowY: "scroll",
          background: "#F2F2F0",
        }}
      >
        <div className="col-md-11">
          <div className="row justify-content-center mt-2" style={{ marginLeft: "40px" }}>
            <div className="col-md-12">
              <div className="row">
                <div
                  className="col-md-1 label"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src="chevron-left.png"
                    alt="location icon"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      props.setMenu("Monitor");
                    }}
                  />
                  <span
                    style={{
                      color: "#0B68CB",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "0.04px",
                      textAlign: "left",
                    }}
                  >
                    {policiesdetails? policiesdetails.insurance_company : null}
                  </span>
                </div>
              </div>
              <div className="row mt-3 label">
                <div className="col-md-1 label">{props.selectedPolicyNo}</div>
                <div className="col-md-2">
                  <span
                    className="p-1"
                    style={{
                      borderColor: "#AED6F1",
                      backgroundColor: "#E1F0FF",
                      color: "#0B68CB",
                    }}
                  >
                    {policiesdetails? policiesdetails.policy_start_date : null} -{policiesdetails? policiesdetails.policy_end_date : null}
                  </span>
                </div>
              </div>
              <Card color="primary" outline className="mt-2">
                <div className="title ms-3">Policy Details</div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-2 policy-label" style={{textAlign:'left'}}>
                      Crop Name
                      <br />
                      <span className="title">{policiesdetails? policiesdetails.crop : null}</span>
                    </div>
                    <div className="col-md-1 policy-label">
                      Market
                      <br />
                      <span className="title">{policiesdetails? policiesdetails.market : null}</span>
                    </div>
                    <div className="col-md-1 policy-label">
                      Strike Price
                      <br />
                      <span className="title">{policiesdetails? policiesdetails.strike_price : null}</span>
                    </div>
                    <div className="col-md-1 policy-label">
                      Exit Price
                      <br />
                      <span className="title">{policiesdetails? policiesdetails.exit_price : null}</span>
                    </div>
                    <div className="col-md-1 policy-label">
                      Actual Price
                      <br />
                      <span className="title">{policiesdetails? policiesdetails.actual_amount : null}</span>
                    </div>
                    <div className="col-md-1 policy-label">
                      Total Payout
                      <br />
                      <span className="title">{policiesdetails? policiesdetails.payout_amount : null}</span>
                    </div>
                    <div className="col-md-2 policy-label">
                      Total Sum Insured
                      <br />
                      <span className="title">{policiesdetails? policiesdetails.sum_insured : null}</span>
                    </div>
                    <div className="col-md-1 policy-label">
                    Aggregator Count 
                      <br />
                      <span className="title">04</span>
                    </div>
                    <div className="col-md-2 policy-label">
                       Endorsee Count
                      <br />
                      <span className="title">{policiesdetails? policiesdetails.endorsee_count : null}</span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div
            className="row justify-content-center mt-3"
            style={{ backgroundColor: "#fff", width: "100vw", paddingLeft: "30px", paddingRight: "30px" }}
          >
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Comparison" value="1" />
                    <Tab label="Endorsee Listed" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div className="row justify-content-between">
                    <div className="col-md-2 title">
                      Price Dataset Comparison
                    </div>
                    <div className="col-md-3">
                      <Button outline>
                        <BiDownload size={20} className="me-2" />
                        Monitoring Report
                      </Button>
                    </div>
                  </div>
                 {comparisonData? <PriceComparison data={comparisonData} /> : null} 
                </TabPanel>
                <TabPanel value="2">
                  <TableContainer component={Paper} style={{ height: 300 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Aggregator Name</TableCell>
                          <TableCell align="right">Endorsee Count</TableCell>
                          <TableCell align="right">Sum Insured</TableCell>
                          <TableCell align="right">Status</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {endorseeData.map((row) => (
                          <TableRow
                            key={row["_id"]}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.aggregator}
                            </TableCell>
                            <TableCell align="right">
                              {row.endorsee_count}
                            </TableCell>
                            <TableCell align="right">{row.sum_insured}</TableCell>
                            <TableCell align="right">{row.status}</TableCell>
                            <TableCell align="right">
                              <Button color="link">
                                Download Endorsee Table
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
