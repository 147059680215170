import React, { useState } from "react";
import "../../styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Label, FormGroup, Input, Button } from "reactstrap";
import { FileUploader } from "react-drag-drop-files";
import { SiMicrosoftexcel } from "react-icons/si";

const PolicyCreation = (props) => {
  const [market, setMarket] = useState(null);
  const [crop, setCrop] = useState(null);
  const [cropVariety, setCropVariety] = useState(null);
  const [embeddedLoan, setEmbeddedLoan] = useState(null);
  const [policyNumber, setPolicyNumber] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [monitoringStart, setMonitoringStart] = useState(null);
  const [monitoringEnd, setMonitoringEnd] = useState(null);
  const [assessmentMethod, setAssessmentMethod] = useState(null);
  const [maDays, setMADays] = useState(null);
  const [deductiblePercentage, setDeductiblePercentage] = useState(null);
  const [sumInsured, setSumInsured] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [units, setUnits] = useState(null);
  const [minPayout, setMinPayout] = useState(null);
  const [maxPayout, setMaxPayout] = useState(null);
  const [insuranceType, setInsuranceType] = useState(null);
  const [strikePrice, setStrikePrice] = useState(null);
  const [exitPrice, setExitPrice] = useState(null);
  const [priceUnit, setPriceUnit] = useState(null);
  const [priceSource, setPriceSource] = useState(null);
  const [policyHolder, setPolicyHolder] = useState(null);
  const [aggregator, setAggregator] = useState(null);
  const [issuer, setIssuer] = useState(null);
  const [broker, setBroker] = useState(null);
  const [marketOptions, setMarketOptions] = useState([]);
  const [varietyOptions, setVarietyOptions] = useState([]);

  const market_options_guntur = [{ value: "Guntur", label: "Guntur" }];
  const market_options_indore = [{ value: "Indore", label: "Indore" }];
  const crop_options = [{ value: "Red Chillies", label: "Red Chilly" },
  { value: "Soyabean", label: "Soyabean" }];
  const variety_options_teja = [{ value: "Teja", label: "Teja" }];
  const variety_options_yellow = [{ value: "Yellow", label: "Yellow" }];
  
  const assessment_method_options = [
    { value: "average", label: "Average" },
    { value: "moving_average", label: "Moving Average" },
  ];
  const ma_options = [
    { value: 5, label: "5 days" },
    { value: 10, label: "10 days" },
    { value: 15, label: "15 days" },
  ];
  const unit_options = [
    { value: "Kg", label: "Kg " },
    { value: "quintals", label: "Quintals" },
  ];
  const price_unit_options = [
    { value: "Rs/Quintal", label: "Rs/Quintal" },
    { value: "Rs/kg", label: "Rs/kg" },
    { value: "Rs/Tonne", label: "Rs/Tonne" },
  ];
  const insurance_type_options = [
    { value: "sowing", label: "Sowing" },
    { value: "post_harvest", label: "Post-Harvest" },
  ];
  const price_source_options = [
    { value: "NCDEX", label: "NCDEX" },
    { value: "Agmarknet", label: "Agmarknet" },
    { value: "E-NAM", label: "E-NAM" },
  ];
  const handleCropChange = (selectedOption) => {
    setCrop(selectedOption.value);
    // Update market options based on the selected crop
    if (selectedOption.value === "Red Chillies") {
      setMarketOptions(market_options_guntur);
      setVarietyOptions(variety_options_teja);
    } else if (selectedOption.value === "Soyabean") {
      setMarketOptions(market_options_indore);
      setVarietyOptions(variety_options_yellow);
    } else {
      setMarketOptions([]);
      setVarietyOptions([]);
    }
    setMarket(null)
    setCropVariety(null)
  };
  const fileTypes = ["csv", "xlsx"];
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  const isButtonDisabled = () => {
    return false;
  };
  const handleTypeError = () => {
    toast.error("🦄 Wow so easy!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: "slide",
    });
  };
  const handleCancel = () => {
    props.setMenu("Monitor");
  };
  const handleCreatePolicy = async (e, obj) => {
    // Ensure all required fields are not null
    if (
      market !== null &&
      crop !== null &&
      cropVariety !== null &&
      embeddedLoan !== null &&
      startDate !== null &&
      endDate !== null &&
      monitoringStart !== null &&
      monitoringEnd !== null &&
      assessmentMethod !== null &&
      // deductiblePercentage !== null &&
      sumInsured !== null &&
      quantity !== null &&
      units !== null &&
      // minPayout !== null &&
      // maxPayout !== null &&
      insuranceType !== null &&
      strikePrice !== null &&
      exitPrice !== null &&
      priceSource !== null &&
      priceUnit !== null &&
      issuer !== null &&
      broker !== null &&
      policyHolder !== null &&
      aggregator !== null &&
      policyNumber !== null
      // file !== null // Ensure file is not null
    ) {
      if (endDate > startDate) {
        try {
          // First, create the policy
          const createPolicyResponse = await fetch(
            "/api/monitor/createPolicy",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                policy_number: policyNumber,
                master_holder: policyHolder,
                aggregator: aggregator,
                insurance_company: issuer,
                broker_name: broker,
                market: market,
                crop: crop,
                crop_variety: cropVariety,
                price_source: priceSource,
                strike_price: strikePrice,
                exit_price: exitPrice,
                assessment_method: assessmentMethod,
                deductible_percentage: deductiblePercentage,
                min_payout_percentage: minPayout,
                max_payout_percentage: maxPayout,
                embedded_in_loan: embeddedLoan,
                insurance_type: insuranceType,
                evaluation_date: "2024-10-26", // Hardcoded as per your example
                policy_start_date: startDate,
                policy_end_date: endDate,
                monitoring_start_date: monitoringStart,
                monitoring_end_date: monitoringEnd,
                status: "Live", // Hardcoded as per your example
                sum_insured: sumInsured,
                uom: units,
                endorsee_count: 100, // Hardcoded as per your example
                actual_amount: 100, // Hardcoded as per your example
                payout_amount: 100, // Hardcoded as per your example
                ma_window_days: maDays,
                quantity: quantity,
                units: units,
                price_unit: priceUnit,
              }),
            }
          );

          const createPolicyData = await createPolicyResponse.json();

          if (file != null) {
            // If policy creation was successful, proceed to upload the file
            const formData = new FormData();
            formData.append("file", file);

            const uploadResponse = await fetch(
              "/api/monitor/uploadEndorseeData",
              {
                method: "POST",
                body: formData,
              }
            );

            const uploadData = await uploadResponse.json();

            if (
              uploadData.message ===
              "CSV file processed and uploaded successfully"
            ) {
              toast.success("New Policy Added and File Uploaded", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                autoClose: 5000, // Ensure the toast closes automatically after 5 seconds
                theme: "light",
              });
            } else {
              toast.error("Policy created but file upload failed!", {
                position: "top-right",
                autoClose: 5000, // Ensure the toast closes automatically after 5 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
              });
            }
          } else if (file == null) {
            toast.success("Policy created but file is not uploaded!", {
              position: "top-right",
              autoClose: 5000, // Ensure the toast closes automatically after 5 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          }
        } catch (error) {
          toast.error(
            "Failed to create policy or upload file. Please try again.",
            {
              position: "top-right",
              hideProgressBar: false,
              autoClose: 5000, // Ensure the toast closes automatically after 5 seconds
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            }
          );
        }
      } else {
        toast.error("Check Date Inputs", {
          position: "top-right",
          autoClose: 5000, // Ensure the toast closes automatically after 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      }
    } else {
      toast.error("Check all input fields", {
        position: "top-right",
        autoClose: 5000, // Ensure the toast closes automatically after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  };

  const handleDownload = () => {
    window.location.href = "/carma.endorsee.csv";
  };
  return (
    <>
      <div className="download-pannel">
        <div
          style={{
            textAlign: "left",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "28px",
            letterSpacing: "-0.04px",
          }}
        >
          <img
            src="chevron-left.png"
            alt="location icon"
            style={{ marginRight: 10 }}
            onClick={() => {
              props.setMenu("Monitor");
            }}
          />
          Policy Creation
          <div
            style={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "25px",
              color: "rgba(7, 6, 0, 0.58)",
              marginTop: "10px",
            }}
          >
            Fill in the required fields to create a policy.
          </div>
        </div>
      </div>
      <div
        className="download-pannel2"
        style={{ width: "100%", background: "rgba(242, 242, 242, 1)" }}
      >
        <div
          className="card"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className="title"
            style={{ display: "flex", flexDirection: "row", padding: 10 }}
          >
            <img
              src="objects.png"
              alt="objects "
              style={{ marginRight: "15px" }}
            />
            Policy Details
          </div>
          <div className="container align-left">
            {/* First Row */}
            <div className="row">
              <div className="col-12">
                <div className="row">
                  {/* Crop Name and Crop Variety */}
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-6">
                        <Label className="label">Crop Name</Label>
                        <Select
                          className="custom-select"
                          options={crop_options}
                          onChange={handleCropChange}
                          placeholder=""
                        />
                      </div>
                      <div className="col-6">
                        <Label className="label">Crop Variety</Label>
                        <Select
                          className="custom-select"
                          options={varietyOptions}
                          onChange={(obj) => setCropVariety(obj["value"])}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  {/* Market Name */}
                  <div className="col-md-4">
                    <Label className="label">Market Name</Label>
                    <Select
                      className="custom-select"
                      options={marketOptions}
                      onChange={(obj) => setMarket(obj["value"])}
                      placeholder=""
                    />
                  </div>
                  {/* Embedded in Loan */}
                  <div className="col-md-4">
                    <FormGroup tag="fieldset" className="mt-2">
                      <legend className="label">Embedded in Loan</legend>
                      <FormGroup check inline>
                        <Input
                          name="yes"
                          type="radio"
                          onClick={() => setEmbeddedLoan("yes")}
                        />
                        <Label className="label" check>
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          name="no"
                          type="radio"
                          onClick={() => setEmbeddedLoan("no")}
                        />
                        <Label className="label" check>
                          No
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>

            {/* Second Row */}
            <div className="row mt-2">
              {/* Policy Number */}
              <div className="col-md-4">
                <Label className="label">Policy Number</Label>
                <Input
                  id="policy_number"
                  name="policy_number"
                  onChange={(e) => setPolicyNumber(e.target.value)}
                />
              </div>
              {/* Policy Duration */}
              <div className="col-md-4">
                <div className="row">
                  <div className="col-6">
                    <Label className="label">Start Date</Label>
                    <Input
                      type="date"
                      className="custom-select"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <Label className="label">End Date</Label>
                    <Input
                      type="date"
                      className="custom-select"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/* Monitoring Duration */}
              <div className="col-md-4">
                <div className="row">
                  <div className="col-6">
                    <Label className="label">Monitoring Start</Label>
                    <Input
                      type="date"
                      onChange={(e) => setMonitoringStart(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <Label className="label">Monitoring End</Label>
                    <Input
                      type="date"
                      onChange={(e) => setMonitoringEnd(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Third Row */}
            <div className="row mt-2">
              {/* Claim Assessment Method */}
              <div className="col-md-4">
                <Label className="label">Claim Assessment Method</Label>
                <Select
                  className="custom-select"
                  options={assessment_method_options}
                  onChange={(obj) => setAssessmentMethod(obj.value)}
                  placeholder=""
                />
              </div>
              {/* Moving Average Window (Days) */}
              <div className="col-md-4">
                <Label className="label">Moving Average Window (Days)</Label>
                <Select
                  className="custom-select"
                  options={ma_options}
                  onChange={(obj) => setMADays(obj.value)}
                  isDisabled={assessmentMethod === "average"}
                />
              </div>
              {/* Deductible (%) */}
              <div className="col-md-2">
                <Label className="label">Deductible (%)</Label>
                <Input
                  type="number"
                  disabled
                  onChange={(e) => setDeductiblePercentage(e.target.value)}
                />
              </div>
            </div>

            {/* Fourth Row */}
            <div className="row mt-2">
              {/* Total Sum Insured */}
              <div className="col-md-4">
                <Label className="label">Total Sum Insured</Label>
                <Input
                  type="number"
                  onChange={(e) => setSumInsured(e.target.value)}
                />
              </div>
              {/* Total Quantity and Units */}
              <div className="col-md-4">
                <div className="row">
                  <div className="col-6">
                    <Label className="label">Total Quantity</Label>
                    <Input
                      type="number"
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <Label className="label">Units</Label>
                    <Select
                      className="custom-select"
                      options={unit_options}
                      onChange={(obj) => setUnits(obj.value)}
                    />
                  </div>
                </div>
              </div>
              {/* Min Payout (%) and Max Payout (%) */}
              <div className="col-md-4">
                <div className="row">
                  <div className="col-6">
                    <Label className="label">Min Payout (%)</Label>
                    <Input
                      type="number"
                      disabled
                      onChange={(e) => setMinPayout(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <Label className="label">Max Payout (%)</Label>
                    <Input
                      type="number"
                      disabled
                      onChange={(e) => setMaxPayout(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className="title"
            style={{ display: "flex", flexDirection: "row", padding: 10 }}
          >
            <img
              src="PriceDetails.svg"
              alt="objects "
              style={{ marginRight: "15px" }}
            />
            Price Details
          </div>
          <div className="container align-left">
            <div className="row">
              <div className="col-6 col-md-4">
                <Label className="label">Type of Insurance</Label>
                <Select
                  className="custom-select"
                  options={insurance_type_options}
                  placeholder=""
                  onChange={(obj) => {
                    setInsuranceType(obj.value);
                  }}
                />
              </div>

              <div className="col-12 col-md-8">
                <div className="row">
                  <div className="col-md-3">
                    <FormGroup>
                      <Label className="label" for="exampleState">
                        Strike Price
                      </Label>
                      <Input
                        id="exampleNumber"
                        name="strike_price"
                        placeholder=""
                        type="number"
                        onChange={(e) => {
                          setStrikePrice(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-3">
                    <FormGroup>
                      <Label className="label" for="exampleState">
                        Exit Price
                      </Label>
                      <Input
                        id="exampleNumber"
                        name="exit_price"
                        placeholder=""
                        type="number"
                        onChange={(e) => {
                          setExitPrice(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="label" for="exampleState">
                            Price Unit
                          </Label>
                          <Select
                            placeholder=""
                            className="custom-select"
                            options={price_unit_options}
                            onChange={(obj) => {
                              setPriceUnit(obj.value);
                            }}
                          ></Select>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="label" for="exampleState">
                            Price Source
                          </Label>
                          <Select
                            className="custom-select"
                            placeholder=""
                            options={price_source_options}
                            onChange={(obj) => {
                              setPriceSource(obj.value);
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className="title"
            style={{ display: "flex", flexDirection: "row", padding: 10 }}
          >
            <img
              src="PolicyHolder.svg"
              alt="objects "
              style={{ marginRight: "15px" }}
            />
            Policy Holder Details
          </div>
          <div className="container align-left">
            <div className="row">
              <div className="col-md-3">
                <Label className="label">Policy Holder</Label>
                <Input
                  id="exampleState"
                  name="master"
                  onChange={(e) => {
                    setPolicyHolder(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-3">
                <FormGroup>
                  <Label className="label" for="exampleAggregator">
                    Aggregator
                  </Label>
                  <Input
                    id="exampleAggregator"
                    name="aggregator"
                    onChange={(e) => {
                      setAggregator(e.target.value);
                    }}
                  />
                </FormGroup>
              </div>

              <div className="col-md-3">
                <FormGroup>
                  <Label className="label" for="exampleState">
                    Insurance company
                  </Label>
                  <Input
                    id="exampleState"
                    name="issuer"
                    onChange={(e) => {
                      setIssuer(e.target.value);
                    }}
                  />
                </FormGroup>
              </div>

              <div className="col-md-3">
                <FormGroup>
                  <Label className="label" for="exampleState">
                    Broker Name
                  </Label>
                  <Input
                    id="exampleState"
                    name="broker"
                    onChange={(e) => {
                      setBroker(e.target.value);
                    }}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-3">
                <div className="title">
                  <img src="world.png" className="me-2" alt="location icon" />
                  Endorsee Information
                </div>
              </div>
            </div>

            <div
              className="row justify-content-between mt-3 p-3"
              style={{
                borderColor: "#e0e0eb",
                borderStyle: "dashed",
                backgroundColor: "#f5f5f5",
              }}
            >
              <div className="col-md-6">
                <div className="title">
                  <SiMicrosoftexcel size={20} className="me-2" />
                  Download Template
                  <div className="col-12">
                    <Label className="menutext">
                      You can download the attached template and use it to fill
                      endorsee table
                    </Label>
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <Button
                  style={{
                    backgroundColor: "#113264",
                    borderColor: "#113264",
                  }}
                  className="ps-5 pe-5 mt-2"
                  onClick={handleDownload}
                >
                  Download
                </Button>
              </div>
            </div>

            <div
              className="row justify-content-center mt-3 p-3"
              style={{
                borderColor: "#e0e0eb",
                borderStyle: "dashed",
                backgroundColor: "#f5f5f5",
                alignItems: "center",
                height: "150px",
              }}
            >
              <div className="col-md-12 d-flex justify-content-center align-items-center">
                <FileUploader
                  handleChange={handleChange}
                  name="endorseeList"
                  types={fileTypes}
                  height="200"
                  label="Drag or drop file to upload"
                  maxSize="5"
                  onTypeError={handleTypeError}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row justify-content-end"
        style={{
          overflowY: "scroll",
          background: "#F2F2F0",
        }}
      >
        <div className="col-md-11">
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="container">
                <div className="row justify-content-end mb-3">
                  <div className="col-12 col-md-2 mt-3">
                    <button
                      style={{
                        padding: "7px 14px", // Adjust padding
                        backgroundColor: isButtonDisabled()
                          ? "rgba(20, 20, 0, 0.05)"
                          : "white", // Conditional background color
                        color: isButtonDisabled()
                          ? "rgba(30, 25, 1, 0.24)"
                          : "black", // Conditional text color
                        border: "none", // No border
                        borderRadius: "5px", // Rounded corners
                        cursor: isButtonDisabled() ? "not-allowed" : "pointer", // Not-allowed cursor when disabled
                        fontSize: "14px", // Font size
                        fontWeight: 500,
                        width: "182px",
                        marginRight: "30px",
                      }}
                      disabled={isButtonDisabled()}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-12 col-md-2 mt-3">
                    <div>
                      {/* Save Button */}
                      <button
                        style={{
                          marginRight: "10px", // Add space between buttons
                          padding: "7px 14px", // Adjust padding
                          backgroundColor: "#0880EA", // Blue background for save button
                          color: "white", // White text color
                          border: "1px solid #0880EA", // No border
                          borderRadius: "5px", // Rounded corners
                          cursor: "pointer", // Pointer cursor

                          fontSize: "14px", // Font size
                          fontWeight: 500,
                          width: "182px", // Font weight
                        }}
                        onClick={handleCreatePolicy}
                      >
                        Create Policy
                      </button>
                    </div>
                  </div>

                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    style={{ marginTop: "30px", width: "350px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyCreation;
