import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography, Button } from "@mui/material";
import SummaryGraph from "./SummaryGraph"; // Import the new SummaryGraph component
import { IoDownloadOutline } from "react-icons/io5";
import axios from 'axios';
const Generate = (props) => {
  const [tabValue, setTabValue] = useState(0);
  const [premium, setPremium] = useState(null);
  const [summaryGraphData, setSummaryGraphData] = useState(null);
  const [file, setFile] = useState(null);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleDownload = async () => {
    try {
        const response = await axios.post(
            '/api/pricing/downloadPriceData', 
            {
                source: props.priceSource,
                commodity: props.cropName,
                market_name: props.marketName,
            }, 
            {
                responseType: 'blob', // This ensures the response is treated as a binary file
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'pricing_data.zip');

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Cleaning up after download

    } catch (error) {
        console.error('Error downloading file:', error);
        alert('Failed to download file');
    }
};



  useEffect(() => {
    const fetchPremium = async () => {
      try {
        const response = await fetch("/api/pricing/premium", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            monitoring_start_date: props.monitoringStartDate,
            monitoring_end_date: props.monitoringEndDate,
            source: props.priceSource,
            strike_price: parseInt(props.strikePrice, 10),
            commodity: props.cropName,
            unit: props.priceUnit,
            exit_price: parseInt(props.exitPrice,10)
          }),
        });
        const data = await response.json();
        setPremium(data.premium); // Assuming 'premium' is the key in the response
      } catch (error) {
        console.error("Error fetching premium data:", error);
      }
    };

    const fetchPayout = async () => {
      try {
        const response = await fetch("/api/pricing/payout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            monitoring_start_date: props.monitoringStartDate,
            monitoring_end_date: props.monitoringEndDate,
            source: props.priceSource,
            strike_price: parseInt(props.strikePrice, 10),
            commodity: props.cropName,
            unit: props.priceUnit,
            exit_price: parseInt(props.exitPrice,10)
          }),
        });
        const data = await response.json();
        setSummaryGraphData(data); // Assuming the whole response is required for the graph
      } catch (error) {
        console.error("Error fetching payout data:", error);
      }
    };

    fetchPremium();
    fetchPayout();
  }, [
    props.generateclicked
  ]); 
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Generate Tabs"
      >
        <Tab
          label="Summary"
          sx={{ textTransform: "capitalize", fontSize: "16px" }}
        />
        <Tab
          label="Risk Classification"
          disabled
          sx={{ textTransform: "capitalize", fontSize: "16px" }}
        />
      </Tabs>
      <Box sx={{ p: 3, position: "relative" }}>
        {tabValue === 0 && (
          <>
            <Box
              sx={{
                width: "300px",
                height: "76px",
                padding: "16px",
                gap: "3px",
                borderRadius: "12px",
                border: "2px solid #0880EA",
                opacity: 1,
                backgroundColor: "#F5FAFF",
                display: "flex",
                alignContent:'flex-start'
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "left",
                }}
              >
                <Box sx={{ flex: 1, paddingLeft: "5px" }}>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", fontWeight: 400, color: "#101D46" }}
                  >
                    Premium Calculated 
                  </Typography>
                  <Typography variant="h6" sx={{color:'#0880EA'}}>
                    {premium !== null ? premium  + "%" : "Loading..."}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Include the SummaryGraph component here */}
            <Box sx={{ mt: 3 }}>
              {summaryGraphData ? (
                <>
                <div style={{fontSize:'16px',fontWeight:500,textAlign:'left',marginBottom:'20px'}}>Historical Payout</div>
                <SummaryGraph data={summaryGraphData} />
                </>
              ) : (
                <Typography>Loading graph...</Typography>
              )}
            </Box>
            <Button
              variant="outlined"
              startIcon={<IoDownloadOutline />}
              // onClick={downloadPdfDocument}
              style={{
                position: "absolute",
                right: "16px",
                height: "35.527px",
                padding: "8px 16px",
                alignItems: "center",
                gap: "8px",
                flexShrink: 0,
                borderRadius: "8px",
                border: "1px solid #451E11",
                marginTop:'35px',
                color:'black'
              }}
              onClick={handleDownload}
            >
              Download data
            </Button>
          </>
        )}
        {tabValue === 1 && <Typography>Risk Classification Content</Typography>}
      </Box>
    </Box>
  );
};

export default Generate;
