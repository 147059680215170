import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import PriceTrend from "./PriceTrend";
import MonthlyAvgPriceGraph from "./MonthlyAvgPriceGraph";
import AvgPriceTable from "./AvgPriceTable";
import HeatMap from "./HeatMap"; // Import the HeatMap component
import CircularProgress from "@mui/material/CircularProgress";
const CompareExplore = (props) => {
  const [tabValue, setTabValue] = useState(0);
  const [priceTrendData, setPriceTrendData] = useState(null);
  const [monthlyAvgVsArrivalData, setMonthlyAvgVsArrivalData] = useState(null);
  const [heatMapData, setHeatMapData] = useState(null); // State to store heat map data
  const [loading, setLoading] = useState(true);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const priceTrendResponse = await fetch(
          `/api/explore/priceGraph?commodity=${props.cropName}&market_name=${props.marketName}&start_year=${props.policyStartYear}&end_year=${props.policyEndYear}&start_month=${props.monitoringStartMonth}&end_month=${props.monitoringEndMonth}`
        );
        const priceTrendData = await priceTrendResponse.json();
        setPriceTrendData(priceTrendData);

        const monthlyAvgVsArrivalResponse = await fetch(
          `api/explore/monthlyAverageVsArrival?commodity=${props.cropName}&market_name=${props.marketName}&start_year=${props.policyStartYear}&end_year=${props.policyEndYear}&start_month=${props.monitoringStartMonth}&end_month=${props.monitoringEndMonth}`
        );
        const monthlyAvgVsArrivalData =
          await monthlyAvgVsArrivalResponse.json();
        setMonthlyAvgVsArrivalData(monthlyAvgVsArrivalData);

        // Fetch heat map data
        const heatMapResponse = await fetch(
          `/api/explore/heatMap?commodity=${props.cropName}&market_name=${props.marketName}&start_year=${props.policyStartYear}&end_year=${props.policyEndYear}&start_month=${props.monitoringStartMonth}&end_month=${props.monitoringEndMonth}`
        );
        const heatMapData = await heatMapResponse.json();
        setHeatMapData(heatMapData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [props.compareclicked]);

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Generate Tabs"
      >
        <Tab
          label="Data Validation"
          sx={{ textTransform: "capitalize", fontSize: "16px" }}
        />
        <Tab
          label="Risk Analytics"
          sx={{ textTransform: "capitalize", fontSize: "16px" }}
        />
        <Tab
          label="Outlook"
          sx={{ textTransform: "capitalize", fontSize: "16px" }}
        />
      </Tabs>
      <Box sx={{ p: 3 }}>
        {tabValue === 0 && (
          <>
            {props.showCompare ? (
              <Box>
                {loading ? (
                  <Typography>Loading...</Typography>
                ) : (
                  <>
                    {heatMapData ? (
                      <>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            textAlign: "left",
                            marginBottom: "20px",
                          }}
                        >
                          Percentage share of good data days
                        </div>
                        <HeatMap data={heatMapData} />
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          margin: "30px",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}

                    <PriceTrend data={priceTrendData} />
                  </>
                )}
              </Box>
            ) : (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  height: "90vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: 'url("Agtuall_compare_map.svg")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1,
                }}
              >
                <div className="selectmodule">
                  Fill the Input Fields from the left panel to start exploring
                  Data & Charts
                </div>
              </div>
            )}
          </>
        )}
        {tabValue === 1 && (
          <>
            {props.showCompare ? (
              <div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    textAlign: "left",
                    marginBottom: "20px",
                  }}
                >
                  Average Monthly Prices ({props.policyStartYear}-
                  {props.policyEndYear})
                </div>
                {monthlyAvgVsArrivalData != null ? (
                  <MonthlyAvgPriceGraph data={monthlyAvgVsArrivalData} />
                ) : (
                  ""
                )}

                {/* <AvgPriceTable /> */}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  height: "90vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: 'url("Agtuall_compare_map.svg")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 1,
                }}
              >
                <div className="selectmodule">
                  Fill the Input Fields from the left panel to start exploring
                  Data & Charts
                </div>
              </div>
            )}
          </>
        )}
        {tabValue === 2 && <Typography>Outlook</Typography>}
      </Box>
    </Box>
  );
};

export default CompareExplore;
